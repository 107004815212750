<template>
    <div ref="formWidgetRef" class="widget-range">
        <div class="widget-range-item relative">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }} <span v-if="widgetRef.validationProperties.required">*</span></label
            >
            <div class="w-full h-5"></div>
            <div class="flex flex-row">
                <span class="font-nubesans font-normal mr-4">{{ widgetRef.instanceProperties.min }}</span>
                <SliderRoot
                    v-model:model-value="widgetRef.runtimeProperties.data"
                    class="relative flex items-center select-none touch-none grow h-5"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    :max="widgetRef.instanceProperties.max"
                    :min="widgetRef.instanceProperties.min"
                    :step="widgetRef.instanceProperties.step"
                >
                    <SliderTrack class="bg-stone-300 relative grow rounded-full h-2">
                        <SliderRange class="absolute bg-green-normal rounded-full h-full" />
                    </SliderTrack>
                    <SliderThumb
                        class="flex justify-center w-5 h-5 bg-white shadow-[0_2px_5px] shadow-blackA7 border border-solid border-green-normal rounded-[10px] hover:bg-green-normal-light focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-green-normal"
                        aria-label="Volume"
                    >
                        <div class="absolute -top-[2rem]">{{ widgetRef.runtimeProperties.data[0] }}</div>
                    </SliderThumb>
                </SliderRoot>
                <span class="font-nubesans font-normal ml-4">{{ widgetRef.instanceProperties.max }}</span>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue';
// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

if (widgetRef.value.runtimeProperties.data == null) {
    widgetRef.value.runtimeProperties.data = [widgetRef.value.instanceProperties.min];
}
</script>

<style lang="postcss" scoped>
.widget-range {
    @apply border-0 h-full w-full;
}
.widget-range-item {
    @apply flex flex-col gap-2;
}
</style>
