<template>
    <div ref="formWidgetRef" class="widget-radio-group">
        <div v-if="widgetRef.instanceProperties.label" class="w-full field-label mb-2">
            <span>{{ widgetRef.instanceProperties.label }}</span> <span v-if="widgetRef.validationProperties.required">*</span>
        </div>
        <div v-for="option in widgetRef.instanceProperties.options" :key="option" class="flex items-center mb-2">
            <div class="widget-radio-group-item" :class="{ 'pointer-events-none': mode == 'edit' }">
                <input
                    type="radio"
                    :id="normalizedName(option)"
                    :value="slugify(option.label)"
                    v-model="widgetRef.runtimeProperties.data"
                    class="mr-2"
                />
                <!-- :class="{ 'pointer-event-none': mode == 'edit' }" -->
                <label :for="normalizedName(option)">{{ option.label }}</label>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import slugify from 'slugify';

// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

const normalizedName = computed(() => {
    return (option) => {
        return widgetRef.value.instanceProperties.id + '-' + slugify(option.label);
    };
});
</script>

<style lang="postcss" scoped>
.widget-radio-group {
    @apply border-0  w-full;
}
.widget-radio-group-item {
    @apply flex items-center;
}
</style>
